<template>
  <v-card>
    <v-card-title>
      <span class="headline">
        {{ info == null ? "Nueva gestión" : "Editar gestión" }}</span
      >
    </v-card-title>
    <v-card-text>
      <v-container>
        <v-row>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              v-model="gestion.periodo"
              :items="periodos"
              :append-icon="periodos.length == 0 ? 'mdi-reload' : ''"
              item-text="idP"
              item-value="idP"
              chips
              @input="getMat"
              clearable
              hide-details
              label="Lista de periodos"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Este periodo no existe</v-list-item-title>
                </v-list-item>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              v-model="gestion.materia"
              auto-select-first
              :items="materiasByp"
              :append-icon="materiasByp.length == 0 ? 'mdi-reload' : ''"
              item-text="nombre"
              item-value="nombre"
              @input="getEstudiantes"
              chips
              clearable
              label="Lista de materias"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title>Este curso no existe</v-list-item-title>
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.nombre"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.codigo"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>

              <template v-slot:selected="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.nombre"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.codigo"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="4" md="4">
            <v-autocomplete
              dense
              v-model="gestion.estudiante"
              :items="dataEstudiantes"
              :append-icon="dataEstudiantes.length == 0 ? 'mdi-reload' : ''"
              item-text="nombre"
              item-value="id"
              chips
              clearable
              label="Lista de estudiantes"
            >
              <template v-slot:no-data>
                <v-list-item>
                  <v-list-item-title
                    >Este estudiante no existe</v-list-item-title
                  >
                </v-list-item>
              </template>
              <template v-slot:item="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.usuario"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.nombre"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
              <template v-slot:selected="{ item }">
                <v-list-item-content>
                  <v-list-item-title v-text="item.usuario"></v-list-item-title>
                  <v-list-item-subtitle
                    v-text="item.nombre"
                  ></v-list-item-subtitle>
                </v-list-item-content>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              v-model="gestion.gestionRequerida"
              label="Gestión requerida"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="12" md="4">
            <v-text-field
              v-model="gestion.observaciones"
              label="Notas"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="2" md="4">
            <v-select
              :items="tipoG"
              item-text="tipo"
              item-value="tipo"
              v-model="gestion.tipoGestion"
              placeholder="Tipo de cliente"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="12" md="12" v-if="gestion['soportes']">
            <v-container v-bind:style="{ 'text-align': 'center' }">
              <v-row>
                <v-col
                  cols="12"
                  md="2"
                  v-for="(data, i) in gestion['soportes'].data"
                  v-bind:key="i"
                >
                  <v-btn
                    small
                    color="primary"
                    v-if="gestion['soportes'].length != 0"
                    @click="goLink(data)"
                    >Ver archivo {{ i + 1 }}</v-btn
                  >
                  <!-- <v-icon @click="deleteL(data)"> mdi-close</v-icon> -->
                </v-col>
                <v-col>
                  <h3
                    color="primary"
                    v-if="
                      gestion['soportes'].length == 0 ||
                      gestion['soportes'] == ''
                    "
                  >
                    No hay archivos cargados
                  </h3>
                </v-col>
              </v-row>
            </v-container>
          </v-col>
          <v-col cols="12" sm="12" md="12">
            <input
              type="file"
              hidden
              multiple
              ref="files"
              @change="listFiles"
            />
            <v-select
              v-model="files"
              :items="files"
              chips
              clearable
              readonly
              prepend-icon="mdi-file"
              multiple
              @click="$refs.files.click()"
              @click:prepend="$refs.files.click()"
              label="* Agregar archivos"
              append-icon
            ></v-select>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="accent" text @click="$emit('gestionEvento', 'close')"
        >Cancelar</v-btn
      >
      <v-btn color=" primary" :disabled="guardando" @click="saveGestion()" text>
        Guardar</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script>
import firebase from "firebase";
import { host } from "../Tools/variables";
import axios from "axios";
import data from "../Tools/data";

export default {
  props: ["info", "periodo"],
  data() {
    return {
      periodos: [],
      tipoG: [],
      dataCursos: [],
      dataEstudiantes: [],
      materiasByp: [],
      guardando: false,
      search: null,
      searchE: null,
      gestion: {
        soportes: [],
        gestionId: null,
        periodo: null,
        materia: null,
        observaciones: null,
        estudiante: null,
        tipoGestion: null,
        gestionRequerida: null,
      },
      files: [],
      lFiles: [],
      files2: [],
    };
  },

  async mounted() {
    // console.log(this.info);
    console.log(this.info["Soportes"]);
    if (this.info != null) {
      await data
        .dataList("/cursos", "lista", "listar", "materias")
        .then((e) => {
          this.dataCursos = e;
        });
      this.gestion.periodo = this.periodo;
      this.gestion.gestionId = this.info.id;
      this.gestion.materia = this.info["Materia"];
      this.gestion.estado = this.info["Estado de la gestión"];
      this.gestion.observaciones = this.info["Notas"];
      this.gestion.tipoGestion = this.info["Tipo de gestión"];
      this.gestion.gestionRequerida = this.info["Gestión requerida"];
      this.gestion.soportes = this.info["Soportes"];
      this.getMat(this.gestion.periodo);
      await this.getEstudiantes();
    }
  },

  async created() {
    await data.dataList("/cursos", "lista", "listar", "materias").then((e) => {
      this.dataCursos = e;
    });
    if (this.$store.state.periodos.length == 0) {
      this.periodos = await data.dataList(
        "/periodos",
        "lista",
        "listar",
        "periodos"
      );
    } else {
      this.periodos = this.$store.state.periodos;
    }
    if (this.$store.state.tipoG.length == 0) {
      this.tipoG = await data.dataList(
        "/tipoGestion",
        "lista",
        "listar",
        "tipoG"
      );
    } else {
      this.tipoG = this.$store.state.tipoG;
    }
  },

  methods: {
    getMat(a) {
      this.materiasByp = [];
      for (var c = 0; c < this.dataCursos.length; c++) {
        if (this.dataCursos[c]["periodo"] == a) {
          this.materiasByp.push({
            nombre: this.dataCursos[c]["cursoBase"],
            codigo: this.dataCursos[c]["codigoMadre"],
          });
        }
      }
    },
    async getEstudiantes() {
      this.dataEstudiantes = [];
      firebase
        .auth()
        .currentUser.getIdToken(true)
        .then((token) => {
          const fd = new FormData();
          fd.append("token", token);
          fd.append("tipo", "lista_estudiantes");
          fd.append("periodo", this.gestion.periodo);
          fd.append("materia", this.gestion.materia);
          axios.post(host + "/cursos", fd).then((result) => {
            let info = result.data.lista;
            for (let e = 0; e < info.length; e++) {
              const element = info[e];
              this.dataEstudiantes.push({
                nombre: element["nombre"],
                usuario: element["Usuario"],
                id: element["_id"],
              });
            }
            if (this.info != null) {
              this.gestion.estudiante = this.dataEstudiantes.find(
                (e) => e.usuario == this.info["Nombre de usuario"]
              )["id"];
            }
            return this.dataEstudiantes;
          });
        });
    },

    listFiles(event) {
      this.files = [];
      this.files2 = Array.prototype.slice.call(event.target.files);
      for (let i = 0; i < this.$refs.files.files.length; i++) {
        this.files.push(this.$refs.files.files[i].name);
        this.lFiles.push(this.$refs.files.files[i]);
      }
    },

    saveGestion() {
      if (this.info == null) {
        this.guardando = true;
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            const fd = new FormData();
            fd.append("token", token);
            fd.append("tipo", "agregar");
            fd.append("userId", this.gestion.estudiante);
            fd.append("periodo", this.gestion.periodo);
            fd.append("observaciones", this.gestion.observaciones);
            fd.append("materia", this.gestion.materia);
            if (this.files2.length > 0) {
              fd.append("soportes", this.files2);
              for (var i = 0; i < this.files2.length; i++) {
                let file = this.files2[i];
                // Here we create unique key 'files[i]' in our response dict
                fd.append("files[" + i + "]", file);
              }
            }
            fd.append("tipoGestion", this.gestion.tipoGestion);
            fd.append("gestionRequerida", this.gestion.gestionRequerida);
            axios.post(host + "/gestiones", fd).then((result) => {
              this.guardando = false;
              let data = result.data.text;
              if (data == "OK") {
                this.$emit("gestionEvento", "refresh");
              } else {
                this.$alert("Error" + result.data);
              }
            });
          });
      } else {
        this.guardando = true;
        firebase
          .auth()
          .currentUser.getIdToken(true)
          .then((token) => {
            const fd = new FormData();
            fd.append("token", token);
            fd.append("tipo", "modificar");
            fd.append("gestionId", this.gestion.gestionId);
            fd.append("userId", this.gestion.estudiante);
            fd.append("periodo", this.gestion.periodo);
            fd.append("observaciones", this.gestion.observaciones);
            fd.append("materia", this.gestion.materia);
            if (this.files2.length > 0) {
              fd.append("soportes", this.files2);
              for (var i = 0; i < this.files2.length; i++) {
                let file = this.files2[i];
                // Here we create unique key 'files[i]' in our response dict
                fd.append("files[" + i + "]", file);
              }
            }
            fd.append("tipoGestion", this.gestion.tipoGestion);
            fd.append("estado", this.gestion.estado);
            fd.append("gestionRequerida", this.gestion.gestionRequerida);
            axios.post(host + "/gestiones", fd).then((result) => {
              this.guardando = false;
              let data = result.data.text;
              if (data == "OK") {
                this.$emit("gestionEvento", "refresh");
              } else {
                this.$alert("Error" + result.data);
              }
            });
          });
      }
    },
    goLink(s) {
      window.open(s, "_blank");
    },
  },
};
</script>
