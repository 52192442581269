var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.info == null ? "Nueva gestión" : "Editar gestión"))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","items":_vm.periodos,"append-icon":_vm.periodos.length == 0 ? 'mdi-reload' : '',"item-text":"idP","item-value":"idP","chips":"","clearable":"","hide-details":"","label":"Lista de periodos"},on:{"input":_vm.getMat},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v("Este periodo no existe")])],1)]},proxy:true}]),model:{value:(_vm.gestion.periodo),callback:function ($$v) {_vm.$set(_vm.gestion, "periodo", $$v)},expression:"gestion.periodo"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","auto-select-first":"","items":_vm.materiasByp,"append-icon":_vm.materiasByp.length == 0 ? 'mdi-reload' : '',"item-text":"nombre","item-value":"nombre","chips":"","clearable":"","label":"Lista de materias"},on:{"input":_vm.getEstudiantes},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v("Este curso no existe")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.codigo)}})],1)]}},{key:"selected",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.nombre)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.codigo)}})],1)]}}]),model:{value:(_vm.gestion.materia),callback:function ($$v) {_vm.$set(_vm.gestion, "materia", $$v)},expression:"gestion.materia"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"4","md":"4"}},[_c('v-autocomplete',{attrs:{"dense":"","items":_vm.dataEstudiantes,"append-icon":_vm.dataEstudiantes.length == 0 ? 'mdi-reload' : '',"item-text":"nombre","item-value":"id","chips":"","clearable":"","label":"Lista de estudiantes"},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-title',[_vm._v("Este estudiante no existe")])],1)]},proxy:true},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.usuario)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.nombre)}})],1)]}},{key:"selected",fn:function(ref){
var item = ref.item;
return [_c('v-list-item-content',[_c('v-list-item-title',{domProps:{"textContent":_vm._s(item.usuario)}}),_c('v-list-item-subtitle',{domProps:{"textContent":_vm._s(item.nombre)}})],1)]}}]),model:{value:(_vm.gestion.estudiante),callback:function ($$v) {_vm.$set(_vm.gestion, "estudiante", $$v)},expression:"gestion.estudiante"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Gestión requerida"},model:{value:(_vm.gestion.gestionRequerida),callback:function ($$v) {_vm.$set(_vm.gestion, "gestionRequerida", $$v)},expression:"gestion.gestionRequerida"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"4"}},[_c('v-text-field',{attrs:{"label":"Notas"},model:{value:(_vm.gestion.observaciones),callback:function ($$v) {_vm.$set(_vm.gestion, "observaciones", $$v)},expression:"gestion.observaciones"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"2","md":"4"}},[_c('v-select',{attrs:{"items":_vm.tipoG,"item-text":"tipo","item-value":"tipo","placeholder":"Tipo de cliente"},model:{value:(_vm.gestion.tipoGestion),callback:function ($$v) {_vm.$set(_vm.gestion, "tipoGestion", $$v)},expression:"gestion.tipoGestion"}})],1),(_vm.gestion['soportes'])?_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-container',{style:({ 'text-align': 'center' })},[_c('v-row',[_vm._l((_vm.gestion['soportes'].data),function(data,i){return _c('v-col',{key:i,attrs:{"cols":"12","md":"2"}},[(_vm.gestion['soportes'].length != 0)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.goLink(data)}}},[_vm._v("Ver archivo "+_vm._s(i + 1))]):_vm._e()],1)}),_c('v-col',[(
                    _vm.gestion['soportes'].length == 0 ||
                    _vm.gestion['soportes'] == ''
                  )?_c('h3',{attrs:{"color":"primary"}},[_vm._v(" No hay archivos cargados ")]):_vm._e()])],2)],1)],1):_vm._e(),_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('input',{ref:"files",attrs:{"type":"file","hidden":"","multiple":""},on:{"change":_vm.listFiles}}),_c('v-select',{attrs:{"items":_vm.files,"chips":"","clearable":"","readonly":"","prepend-icon":"mdi-file","multiple":"","label":"* Agregar archivos","append-icon":""},on:{"click":function($event){return _vm.$refs.files.click()},"click:prepend":function($event){return _vm.$refs.files.click()}},model:{value:(_vm.files),callback:function ($$v) {_vm.files=$$v},expression:"files"}})],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"accent","text":""},on:{"click":function($event){return _vm.$emit('gestionEvento', 'close')}}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"color":" primary","disabled":_vm.guardando,"text":""},on:{"click":function($event){return _vm.saveGestion()}}},[_vm._v(" Guardar")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }